<template>
  <div class="w-full xl:px-36 sm:px-8">
    <!-- MY BUSINESS -->
    <div>
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center">
          <strong>&nbsp;Mi Negocio</strong>
        </h1>
        <div class="ml-auto">
          <sa-button
            type="primary"
            class="items-center px-3 py-3 font-bold rounded"
            @click="save"
            v-loading.fullscreen.lock="loading"
          >
            {{ saveButtonLabel }}
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-6">
        <el-form :model="model">
          <content-card class="mb-4">
            <div class="w-full flex flex-col lg:flex-row">
              <el-form-item class="w-full lg:w-4/12 mx-2" prop="name">
                <strong>Nombre</strong>
                <el-input v-model="model.name" />
              </el-form-item>
              <el-form-item class="w-full lg:w-1/4 mx-2" prop="country">
                <strong>País</strong>
                <el-select class="w-full" clearable v-model="model.country">
                  <el-option
                    class="h-full"
                    :key="'Otro'"
                    :value="'Otro'"
                    :label="country"
                  ></el-option>
                  <el-option class="h-full" :value="undefined" disabled>
                    <div class="flex flex-col py-1 text-center">
                      <span class="text-gray-500">---Paises Americanos---</span>
                    </div>
                  </el-option>
                  <el-option
                    v-for="country in americanCountries"
                    :key="country"
                    :value="country"
                    :label="country"
                  ></el-option>
                  <el-option class="h-full" :value="undefined" disabled>
                    <div class="flex flex-col py-1 text-center">
                      <span class="text-gray-500">---Paises Europeos---</span>
                    </div>
                  </el-option>
                  <el-option
                    class="h-full"
                    v-for="country in europeanCountries"
                    :key="country"
                    :value="country"
                    :label="country"
                  ></el-option>
                  <el-option class="h-full" :value="undefined" disabled>
                    <div class="flex flex-col py-1 text-center">
                      <span class="text-gray-500">---Otros---</span>
                    </div>
                  </el-option>
                  <el-option
                    class="h-full"
                    value="otro"
                    label="Otro"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="w-full lg:w-4/12 mx-2"
                prop="country_name"
                v-if="model.country === 'Otro'"
              >
                <strong>Escriba el nombre de su país</strong>
                <el-input v-model="model.custom_country" />
              </el-form-item>
              <el-form-item class="w-full lg:w-1/4 mx-2" prop="phone">
                <strong>Teléfono</strong>
                <sa-phone-country :model="model" />
              </el-form-item>
            </div>
            <div class="w-full flex flex-col lg:flex-row">
              <el-form-item class="w-full lg:w-1/4 mx-2" prop="currency">
                <strong>Tipo de Moneda</strong>
                <el-select class="w-full" v-model="model.currency">
                  <el-option value="USD" label="USA" />
                  <el-option value="MXN" label="MÉXICO" />
                  <el-option value="EUR" label="EURO" />
                  <el-option value="ARS" label="ARGENTINA" />
                  <el-option label="COLOMBIA" value="COP" />
                  <el-option label="ECUADOR" value="ECS" />
                  <el-option label="GUATEMALA" value="GTQ" />
                  <el-option label="VENEZUELA" value="BS" />
                  <el-option label="PERU" value="PEN" />
                  <el-option label="CHILE" value="CLP" />
                  <el-option label="REPUBLICA DOMINICANA" value="DOP" />
                  <el-option label="HONDURAS" value="HNL" />
                  <el-option label="URUGUAY" value="UYU" />
                  <el-option label="NICARAGUA" value="NIO" />
                  <el-option label="PARAGUAY" value="PYG" />
                  <el-option label="BOLIVIA" value="BOB" />
                  <el-option label="EL SALVADOR" value="SVC" />
                  <el-option label="CUBA" value="CUP" />
                  <el-option label="COSTA RICA" value="CRC" />
                  <el-option label="BRASIL" value="BRL" />
                  <el-option label="PANAMA" value="PAB" />
                  <el-option label="BELICE" value="BZD" />
                  <el-option label="CANADA" value="CAD" />
                  <el-option label="SUIZA" value="CHF" />
                </el-select>
              </el-form-item>

              <el-form-item class="w-full lg:w-1/4 mx-2" prop="timezone">
                <strong>Zona Horaria</strong>
                <el-select class="w-full" filterable v-model="model.timezone">
                  <el-option
                    v-for="timezone_ in timeZones"
                    :key="timezone_.id"
                    :label="timezone_.displayName"
                    :value="timezone_.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item class="w-full lg:w-1/4 mx-2" prop="dateFormat">
                <strong>Formato de Fecha</strong>
                <el-select class="w-full" v-model="model.dateFormat">
                  <el-option
                    v-for="dateFormat in dateFormats"
                    :key="dateFormat"
                    :label="dateFormat.toLowerCase()"
                    :value="dateFormat"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="w-full flex flex-col lg:flex-row">
              <el-form-item class="w-full mx-2" prop="address">
                <strong>Dirección</strong>
                <el-input type="textarea" :rows="3" v-model="model.address" />
              </el-form-item>
            </div>
          </content-card>
          <content-card>
            <div class="w-full inline-flex justify-between mb-4 items-center">
              <strong class="mr-4">Logo</strong>
              <div v-if="model.logo">
                <el-button @click.stop="imageRemove"> Limpiar Logo </el-button>
              </div>
            </div>
            <div class="w-full inline-flex">
              <input
                type="file"
                @change="imageUpload"
                v-if="!model.logo"
                accept="image/*"
              />
              <img
                v-if="model.logoSrc"
                :src="model.logoSrc"
                alt="logo"
                width="300px"
              />
            </div>
          </content-card>
        </el-form>
      </div>
    </div>

    <!-- TAXES -->
    <div v-if="showTaxesConfig" ref="taxesConfigRef">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>&nbsp;Impuestos Fijos</strong>
        </h1>
        <div class="ml-auto">
          <sa-button
            type="primary"
            class="items-center px-3 py-3 font-bold rounded"
            @click="onBusinessTaxCreate"
          >
            Crear
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card
          v-loading.fullscreen.lock="loading"
          title="Configuración de impuestos fijos para ordenes"
        >
          <table
            class="text-left border border-collapse border-slate-500 border-separate border-spacing-2"
          >
            <thead>
              <tr>
                <th class="border border-slate-600"></th>
                <th class="border border-slate-600">Nombre</th>
                <th class="border border-slate-600">Porcentaje</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tax in $store.state.catalogs.businessTaxes"
                :key="tax.id"
              >
                <td class="border border-slate-600 w-24">
                  <sa-group-action-button
                    @edit="onBusinessTaxEdit(tax)"
                    @delete="onBusinessTaxDelete(tax.id)"
                  ></sa-group-action-button>
                </td>
                <td class="border border-slate-600">{{ tax.name }}</td>
                <td class="border border-slate-600">{{ tax.percentage }}</td>
              </tr>
            </tbody>
          </table>
        </content-card>
      </div>
    </div>
    <el-dialog :visible.sync="visible">
      <business-tax-form
        :tax="tax"
        @save="onBusinessTaxSave($event)"
      ></business-tax-form>
    </el-dialog>
  </div>
</template>
<style>
.phone-country-code {
}

.phone-country-code > .el-input__inner {
  padding-left: 85px;
}

.phone-country-code > .el-input__prefix {
  width: 75px;
  left: 0px;
}

.phone-country-code > .el-input__prefix > .el-select > .el-input > input {
  padding-left: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
<script>
import _ from "lodash";
import {
  AmericanCountries,
  EuropeanCountries,
  FileType,
  DateFormats,
} from "@/constants";
import FileService from "@/services/FileService";
import error from "@/mixins/error";
import CatalogService from "@/services/CatalogService";
import BusinessTaxService from "@/services/BusinessTaxService";
import { CATALOGS_STORE } from "@/store/modules/catalogs/mutation-types";
import { sanityCheck } from "@/utils/logos";

export default {
  name: "BusinessForm",
  props: {
    saveButtonLabel: {
      type: String,
      default: () => "Guardar",
    },
    showTaxesConfig: {
      type: Boolean,
      default: () => true,
    },
  },
  mixins: [error],
  components: {
    ContentCard: () => import("@/components/molecules/ContentCard.vue"),
    BusinessTaxForm: () => import("@/components/forms/BusinessTaxForm.vue"),
  },
  data() {
    return {
      americanCountries: AmericanCountries,
      europeanCountries: EuropeanCountries,
      dateFormats: DateFormats,
      loading: false,
      errorMessage: undefined,
      model: {
        name: undefined,
        country: undefined,
        phone: undefined,
        address: undefined,
        logo: undefined,
        logoSrc: undefined,
        currency: undefined,
        timezone: undefined,
        phoneCountryCodeId: undefined,
        dateFormat: undefined,
        custom_country: undefined,
      },
      timeZones: [],
      visible: false,
      tax: {
        id: null,
        name: "",
        percentage: 0,
      },
    };
  },
  mounted() {
    this.load();
    if (this.$route.params.scrollToElementInSlot) {
      setTimeout(() => {
        this.scrollToElement(this.$route.params.scrollToElementInSlot);
      }, 200);
    }
  },
  watch: {
    errorMessage: {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300),
    },
  },
  methods: {
    scrollToElement(refName) {
      const container = this.$refs[refName];
      if (container) {
        container.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    async imageRemove() {
      this.model.logo = undefined;
      this.model.logoSrc = undefined;
    },
    async imageUpload(imgFile) {
      if (imgFile?.target?.files?.length) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imgFile.target.files[0]);
        fileReader.onload = (event) => {
          this.model.logoSrc = event.target.result;
          this.model.logo = imgFile.target.files[0];
        };
      }
    },
    async load() {
      const timezones = await CatalogService.getTimeZones();
      this.timeZones = timezones;
      const data = await this.$store.state.business;
      this.model = {
        id: data.id,
        name: data.name,
        phone: data.phone,
        address: data.address,
        country: data.custom_country ?? data.country,
        logo: data.logo,
        logoSrc: data.logo
          ? `${process.env.VUE_APP_S3_BUCKET}/${data.logo}`
          : undefined,
        currency: data.currency,
        timezone: data.timezone,
        phoneCountryCodeId: data.phoneCountryCodeId,
        dateFormat: data.dateFormat,
      };
    },
    async processLogo(logo) {
      if (!_.isNil(logo) && !_.isEmpty(logo)) {
        const regex = /(?:https?:\/\/)?(?:[^/]+\/)+/;
        const cleanedLogo = logo.replace(regex, "");
        const logoIndex = cleanedLogo.indexOf("businessInfo/");

        if (logoIndex !== -1) {
          return cleanedLogo.substring(logoIndex);
        }
        return sanityCheck("businessinfo", cleanedLogo);
      } else if (!_.isNil(logo)) {
        return await this.createFile(logo);
      }

      return null;
    },
    async save() {
      this.loading = true;
      try {
        const logo = await this.processLogo(this.model.logo);
        const model = {
          ...this.model,
          country: this.model.custom_country ?? this.model.country,
          logo,
        };
        this.$emit("onMyBusinessFormSave", model);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async createFile(logo) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("File", logo);
        formData.append("Type", FileType.BusinessInfo);
        return await FileService.create(formData);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },

    // BUSINESS TAXES
    onBusinessTaxCreate() {
      this.tax = {
        id: null,
        name: "",
        percentage: 0,
      };
      this.visible = true;
    },
    onBusinessTaxSave(tax) {
      if (tax.id) {
        this.updateBusinessTax(tax);
      } else {
        this.createBusinessTax(tax);
      }
    },
    onBusinessTaxEdit(tax) {
      this.tax = { ...tax };
      this.visible = true;
    },
    onBusinessTaxDelete(taxId) {
      this.$confirm(`¿Está seguro que desea eliminar este impuesto fijo?`, {
        type: "warning",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then(() => {
        this.deleteBusinessTax(taxId);
      });
    },
    async createBusinessTax(tax) {
      this.loading = true;
      try {
        const { id: businessTaxId } = await BusinessTaxService.create({
          name: tax.name,
          percentage: tax.percentage,
        });

        this.$store.dispatch(CATALOGS_STORE, {
          ...this.$store.state.catalogs,
          businessTaxes: [
            ...this.$store.state.catalogs.businessTaxes,
            {
              id: businessTaxId,
              name: tax.name,
              percentage: parseFloat(tax.percentage),
            },
          ],
        });
        this.$toastr.s("Se creó con éxito");
        this.visible = false;
        this.tax = {
          id: null,
          name: "",
          percentage: 0,
        };
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async updateBusinessTax(tax) {
      this.loading = true;
      try {
        await BusinessTaxService.update({
          id: tax.id,
          name: tax.name,
          percentage: tax.percentage,
        });

        this.$store.dispatch(CATALOGS_STORE, {
          ...this.$store.state.catalogs,
          businessTaxes: this.$store.state.catalogs.businessTaxes.map(
            (businessTax) =>
              businessTax.id === tax.id
                ? { id: tax.id, name: tax.name, percentage: tax.percentage }
                : businessTax
          ),
        });
        this.$toastr.s("Se actualizó con éxito");
        this.visible = false;
        this.tax = {
          id: null,
          name: "",
          percentage: 0,
        };
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteBusinessTax(taxId) {
      this.loading = true;
      try {
        await BusinessTaxService.delete(taxId);
        this.$store.dispatch(CATALOGS_STORE, {
          ...this.$store.state.catalogs,
          businessTaxes: this.$store.state.catalogs.businessTaxes.filter(
            (businessTax) => businessTax.id !== taxId
          ),
        });
        this.$toastr.s("Se eliminó con éxito");
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
